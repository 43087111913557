<template>
  <b-row>
    <b-col
      md="6"
      class="p-4 mx-auto"
    >
      <b-card v-if="mode === 'verify'">
        <b-card-header
          class="pl-0 pt-0 "
          style="display:inline !important"
        >
          <b-card-title class="text-center text-primary">
            <feather-icon
              icon="CheckSquareIcon"
              size="70"
              class="mb-1 success"
              color="#28c76f"
            />
            Verify Your Account
          </b-card-title>
        </b-card-header>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col
                md="10"
                class="mx-auto"
              >
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-form-group label="Name">
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Full Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="6">
                <b-form-group label="Address">
                  <validation-provider
                    #default="{ errors }"
                    name="Company Address"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.address"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Company Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
                  <!-- <b-col md="6">
                <b-form-group label="Email">
                  <validation-provider
                    #default="{ errors }"
                    name="Company Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="form.email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
                  <b-col cols="12">
                    <b-form-group label="Password">
                      <validation-provider
                        #default="{ errors }"
                        vid="password"
                        name="Password"
                        rules="required|min:8"
                      >
                        <b-input-group
                          :class="errors.length > 0 ? 'is-invalid':null"
                          class="input-group-merge"
                        >
                          <b-form-input
                            v-model="form.password"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Your Password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="() => passwordFieldType = passwordFieldType === 'password' ? 'text' : 'password'"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Confirm Password">
                      <validation-provider
                        #default="{ errors }"
                        name="Confirm Your Password"
                        rules="required|confirmed:password|min:8"
                      >
                        <b-input-group
                          :class="errors.length > 0 ? 'is-invalid':null"
                          class="input-group-merge"
                        >
                          <b-form-input
                            v-model="form.password_confirmation"
                            :type="passwordFieldTypeRetype"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Password Confirmation"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIconRetype"
                              class="cursor-pointer"
                              @click="() => passwordFieldTypeRetype = passwordFieldTypeRetype === 'password' ? 'text' : 'password'"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
        <div>
          <b-row>
            <b-col
              md="11"
              class="d-flex justify-content-end mt-1"
            >
              <b-button
                variant="primary"
                :disabled="isProcessing"
                @click="handleOk"
              >
                <b-spinner
                  v-show="isProcessing"
                  small
                />
                Verify & Register
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card v-else-if="mode=== 'resend-success'">
        <b-card-header class="pl-0 pt-0 mb-2 flex-column">
          <feather-icon
            icon="CheckCircleIcon"
            size="70"
            class="mb-1 success"
            color="#28c76f"
          />
          <b-card-title>Email Sent</b-card-title>
        </b-card-header>
        <b-card-body>
          <p style="text-align: center !important">
            A confirmation email has been sent to  <b>{{ email }} </b>.
          </p>
          <p style="text-align: center !important">
            Please click on the link in the email to verify yourself.
          </p>
          <div class="text-center">
            <b-button
              variant="primary"
              class="mb-2 btn-sm-block mt-1"
              :to="{path:'/'}"
            >
              Back to home
            </b-button>
          </div>
        </b-card-body>
      </b-card>
      <b-card v-else-if="mode=== 'already-registered'">
        <b-card-header class="pl-0 pt-0 mb-2 flex-column">
          <feather-icon
            icon="AlertCircleIcon"
            size="70"
            class="mb-1 success"
            color="#ff9900"
          />
          <b-card-title>Oops! You are already registered</b-card-title>
        </b-card-header>
        <b-card-body>
          <p style="text-align: center !important">
            It seems <b>{{ email }}</b> is already registered.
          </p>
          <div class="text-center">
            <b-button
              variant="primary"
              class="mb-2 btn-sm-block mt-1"
              :to="{path:'/'}"
            >
              Back to home
            </b-button>
          </div>
        </b-card-body>
      </b-card>
      <b-card
        v-else
        class="resend-card"
      >
        <b-card-header class="pl-0 pt-0 mb-2 flex-column">
          <feather-icon
            icon="AlertCircleIcon"
            size="70"
            color="#ff9900"
            class="mb-1"
          />
          <b-card-title>Oops! We couldn't verify you!</b-card-title>
        </b-card-header>

        <b-form-group label="Resend verification email at:">
          <b-form-input
            v-model="email"
            disabled
          />
        </b-form-group>

        <b-button
          variant="primary"
          class="float-right"
          :disabled="(isProcessing || isSent)"
          @click="resendVerification"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          Resend
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  VBModal, BForm, BFormGroup, BFormInput, BSpinner, BButton,
  BCard, BCardHeader, BCardTitle, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'

const defaultForm = () => ({
  id: null,
  name: '',
  type: '0',
  password: '',
  email: '',
  address: '',
  password_confirmation: '',
  company: {
    name: '',
    email: '',
    address: '',
  },
})
export default {
  components: {
    ValidationProvider,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    BCard,
    BSpinner,
    BButton,
    BCardHeader,
    BCardTitle,
    BInputGroupAppend,
    BInputGroup,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      // email,
      title: 'Create Company',
      isProcessing: false,
      form: {
        ...defaultForm(),
      },
      mode: 'verify',
      isSent: false,
      token: this.$route.query.token,
      email: this.$route.query.email,
      passwordFieldType: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // localStorage.removeItem('userData')
    // localStorage.removeItem('accessToken')
    localStorage.clear()
  },
  methods: {
    handleOk() {
      // bvModalEvt.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.auth.verifyAccount({
            token: this.token,
            name: this.form.name,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
          })
            .then(response => {
              const userData = response.data.verifyEmail
              localStorage.setItem('userData', JSON.stringify(userData.user))
              localStorage.setItem('accessToken', userData.access_token)
              // useJwt.setToken(userData.access_token)

              this.$router.replace({ name: 'home' }).then(() => {
              // this.$store.dispatch('authEvents/onUserLogin')
                this.showSuccessMessage({
                  data: {
                    message: `Welcome ${userData.name}, your account has been created & you are loged in`,
                  },
                })
              })
            }).catch(error => {
              this.showError(error.toString())
              this.mode = 'reverify'
              this.isProcessing = false
            })
            .finallly(() => {
              this.isProcessing = false
            })
        }
      })
    },
    resendVerification() {
      this.isProcessing = true
      useApollo.auth.resendVerificationEmail({
        email: this.email,
      }).then(response => {
        this.showInfoMessage({
          title: 'Email Sent',
          message: response.data.resendVerificationEmail.message,
        })
        this.isProcessing = false
        // eslint-disable-next-line eqeqeq
        if (response.data.resendVerificationEmail.status !== 'USER_ALREADY_REGISTERED') {
          this.mode = 'resend-success'
        } else {
          this.mode = 'already-registered'
        }

        this.isSent = true
      })
    },
  },
}
</script>
